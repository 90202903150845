<template>
<div>
    <template v-for="(item,index) in this.$store.state.admin.adminOrders">
        <OperatorPreparationItem :key="index" :nextStatus='6' :currentStatus='[1,5,7,8]' :item="item" />
    </template>
</div>
</template>

<script>
import OperatorPreparationItem from '@/components/OperatorPreparationItem.vue'
export default {
    components: {

        OperatorPreparationItem
    },
        head: {
        title: {
            inner: 'История',
            separator: '-',
            complement: 'Администратор'
        },
    },
    watch: {
        '$store.state.admin.dayOfWeek': {
            //immediate: true,
            async handler() {
                const data = {
                    status_id: JSON.stringify([1,5,7,8]),
                   // date: this.$store.state.admin.dayOfWeek
                }
              //  if (this.$store.state.admin.dayOfWeek == 0) {
                    delete data.date;
              //  }
                this.$store.commit("loader", true);
                await this.$store.dispatch("actionGetAdminOrders", data);
                this.$store.commit("loader", false);
            }
        }
    },
    async mounted() {
        const data = {
            status_id: JSON.stringify([1,5,7,8]),
            //date: this.$store.state.admin.dayOfWeek
        }
       // if (this.$store.state.admin.dayOfWeek == 0) {
            delete data.date;
       // }
        this.$store.commit("loader", true);
        await this.$store.dispatch("actionGetAdminOrders", data);
        this.$store.commit("loader", false);
    },
}
</script>
